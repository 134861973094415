import Navbar from "../componenets/Navbar";
import Sidebar from "../componenets/Sidebar";
import React from 'react';
import TotalFaildMessages from "../admin/components/TotalFaildMessages";

import { Button } from "@mui/material";
import { read, utils, writeFile } from 'xlsx';

 function FaildMessagesList() {

    const FailedMessages = async(req, res) => {

        const dataResponse = await fetch(`http://bpodistributions.click:3010/reports/all_failed_messages_report`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                }
              })
            // convert data to json
            const json = await dataResponse.json();
          //XLSX conversion

          var wb = utils.book_new();
          var ws = utils.json_to_sheet(json);
          
          utils.book_append_sheet(wb, ws, "MySheet1");

          writeFile(wb, "failedMessages.xlsx");
    } 

    return(
        <>
            <Navbar />
            <div className="row">
                <Sidebar />
                
            </div> 
            <main className="main-content position-relative h-100 border-radius-md contained ">
                <div className="container-fluid g-3 ">
                <h4 className="colorfix sizefix mx-2">All Failed Messages</h4>
                    <div className="card px-2 pt-3 pb-3 mb-4 cardbg">
                        <div className="container-fluid" style={{backgroundColor:"white"}}>
                            <br />
                            <Button variant="contained" size="small" onClick={FailedMessages}> download Report</Button>
                            <div className="container-fluid px-0 ">
                           
                                <div className="col-12">
                                    <div className="container-fluid pb-4 pt-3 g-3">
                                        <div className="row g-0">
                                            <TotalFaildMessages />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default FaildMessagesList ;