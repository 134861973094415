import React, { useState, useEffect} from 'react';
import { NavLink } from "react-router-dom";
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import axios from 'axios'
import GroupAddIcon from '@mui/icons-material/GroupAdd';
// import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
// import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
// import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
// import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';



const Sidebar = () => {
    const role = localStorage.getItem('role');

    if(role === '0'){

        return ( 
            <aside className="sidenav navbar navbar-responsive navbar-vertical navbar-expand-xs border-0 mt-6 my-0 px-0 fixed-start ms-0 bg-gray-200" id="sidenav-main">
                
                <div className="collapse navbar-collapse  w-auto" id="sidenav-collapse-main" >
                    <ul className="navbar-nav navspacer">
                        <li className="nav-item">
                            <NavLink  className="nav-link" to="/dashboard" >
                                <SpeedOutlinedIcon style={{fontSize:"xx-large", marginLeft: "5px"}}></SpeedOutlinedIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="nav-item ">
                            <NavLink className="nav-link" to="/add_customer" onClick={localStorage.setItem('rendercomp', 0)}>
                                <PersonAddIcon style={{fontSize:"xx-large",  marginLeft: "5px"}}></PersonAddIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Add Bills</span>
                            </NavLink>
                        </li>
                        
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/messages">
                                <EmailIcon style={{fontSize:"xx-large", marginLeft: "5px"}}></EmailIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Send Bills</span>
                            </NavLink>
                        </li>
                        <li className="nav-item ">
                            <NavLink className="nav-link" to="/sendmessages" >
                                <MarkEmailReadIcon style={{fontSize:"xx-large",  marginLeft: "5px"}}></MarkEmailReadIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Successfuly Sent Messages</span>
                            </NavLink>
                        </li>
                       <li className="nav-item ">
                            <NavLink className="nav-link" to="/faildmessages" >
                                <UnsubscribeIcon style={{fontSize:"xx-large",  marginLeft: "5px"}}></UnsubscribeIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Failed Messages</span>
                            </NavLink>
                        </li>
                        <li className="nav-item ">
                            <NavLink className="nav-link" to="/allcustomers" >
                                <PeopleAltIcon style={{fontSize:"xx-large",  marginLeft: "5px"}}></PeopleAltIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>All Customers</span>
                            </NavLink>
                        </li>
                        {/* <li className="nav-item">
                            <NavLink className="nav-link" to="/profile/mydetails">
                                <AccountBoxOutlinedIcon style={{fontSize:"xx-large", marginLeft: "5px"}}></AccountBoxOutlinedIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Profile</span>
                            </NavLink>
                        </li>
                        <li className="nav-item ">
                            <NavLink className="nav-link" to="/account" >
                                <CalculateOutlinedIcon style={{fontSize:"xx-large",  marginLeft: "5px"}}></CalculateOutlinedIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Account</span>
                            </NavLink>
                        </li>
                        <li className="nav-item ">
                            <NavLink className="nav-link" to="/payment" >
                                <AppRegistrationOutlinedIcon style={{fontSize:"xx-large",  marginLeft: "5px"}}></AppRegistrationOutlinedIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Payment</span>
                            </NavLink>
                        </li> */}
                        
                        
                        {/* <li className="nav-item ">
                            <NavLink className="nav-link" to="/send_message" onClick={localStorage.setItem('rendercomp', 0)}>
                                <WhatsAppIcon style={{fontSize:"xx-large",  marginLeft: "5px"}}></WhatsAppIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Send Message</span>
                            </NavLink>
                        </li> */}
                    </ul>
                </div>
            </aside>
         );
    }
    if(role === '1'){
        return ( 
            <aside className="sidenav navbar navbar-responsive navbar-vertical navbar-expand-xs border-0 mt-6 my-0 px-0 fixed-start ms-0 bg-gray-200" id="sidenav-main">
                <hr className="horizontal dark mt-0" />
                <div className="collapse navbar-collapse  w-auto" id="sidenav-collapse-main" >
                    <ul className="navbar-nav navspacer">
                        <li className="nav-item">
                            <NavLink  className="nav-link" to="/admin_dashboard" >
                                <SpeedOutlinedIcon style={{fontSize:"xx-large", marginLeft: "5px"}}></SpeedOutlinedIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="nav-item ">
                            <NavLink className="nav-link" to="/adduser" onClick={localStorage.setItem('rendercomp', 0)}>
                                <AssignmentIndIcon style={{fontSize:"xx-large",  marginLeft: "5px"}}></AssignmentIndIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Add User</span>
                            </NavLink>
                        </li>
                        <li className="nav-item ">
                            <NavLink className="nav-link" to="/addcustomer_single" onClick={localStorage.setItem('rendercomp', 0)}>
                                <PersonAddIcon style={{fontSize:"xx-large",  marginLeft: "5px"}}></PersonAddIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Add Customer</span>
                            </NavLink>
                        </li>
                        <li className="nav-item ">
                            <NavLink className="nav-link" to="/add_customer" onClick={localStorage.setItem('rendercomp', 0)}>
                                <GroupAddIcon style={{fontSize:"xx-large",  marginLeft: "5px"}}></GroupAddIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Add Customers & Bills</span>
                            </NavLink>
                        </li>
                         <li className="nav-item">
                            <NavLink className="nav-link" to="/messages">
                                <EmailIcon style={{fontSize:"xx-large", marginLeft: "5px"}}></EmailIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Send Bills</span>
                            </NavLink>
                        </li>
                        <li className="nav-item ">
                            <NavLink className="nav-link" to="/sendmessages" >
                                <MarkEmailReadIcon style={{fontSize:"xx-large",  marginLeft: "5px"}}></MarkEmailReadIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Successfuly Sent Messages</span>
                            </NavLink>
                        </li>
                       <li className="nav-item ">
                            <NavLink className="nav-link" to="/faildmessages" >
                                <UnsubscribeIcon style={{fontSize:"xx-large",  marginLeft: "5px"}}></UnsubscribeIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Failed Messages</span>
                            </NavLink>
                        </li>
                        <li className="nav-item ">
                            <NavLink className="nav-link" to="/allcustomers" >
                                <PeopleAltIcon style={{fontSize:"xx-large",  marginLeft: "5px"}}></PeopleAltIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>All Customers</span>
                            </NavLink>
                        </li>
                        {/* <li className="nav-item ">
                            <NavLink className="nav-link" to="/adduser" >
                                <GroupAddIcon style={{fontSize:"xx-large",  marginLeft: "5px"}}></GroupAddIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Add User</span>
                            </NavLink>
                        </li> */}
                        
                        
                        <li className="nav-item ">
                            <NavLink className="nav-link" to="/send_message" onClick={localStorage.setItem('rendercomp', 0)}>
                                <WhatsAppIcon style={{fontSize:"xx-large",  marginLeft: "5px"}}></WhatsAppIcon>
                                <span className="nav-link-text" style={{fontSize: "13.5px"}}>Send Broadcast Message</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </aside>
         );
    }
    
}
export default Sidebar ;