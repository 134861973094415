import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../componenets/Navbar";
import Sidebar from "../componenets/Sidebar";
import Footer from "../admin/components/Footer";
import SentMessageTable from '../admin/components/SentMessagesTable';

let initialvalues = {
    allmessages: '',
    failedmessages: '',
    sendmessages:'',
    archivemessages: ''
 }


 const Dashboard = () =>{

    const [data, setData] = useState(initialvalues);
    const [messages, setMessages] = useState();
    const [dataErrors, setDataErrors] = useState({});

    useEffect(()=> {

        //test preview
        const fetchData = async () => {
            // get the data from the api
            const dataResponse = await fetch(`http://bpodistributions.click:3010/messages/get_messages_values`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                }
              })
            // convert data to json
            const json = await dataResponse.json();
            console.log(json.length)
            initialvalues = {
                allmessages: json.allmessages,
                failedmessages: json.failedmessages,
                sendmessages:json.sendmessages,
                archivemessages: json.archivemessages
            }
            setData(initialvalues)
            setMessages(json);
        }
        fetchData()
        

        
    },[dataErrors]); 



    return(
        <>

            <Navbar />
            <Sidebar />
            <main className="main-content position-relative h-100 border-radius-md contained">
                <div className="container-fluid g-2">
                <h4 className=' mx-4 mb-3' style={{fontSize:'25px', color: 'black'}}>Dashboard</h4>
                    <div className="card pt-4 pb-3 mb-4 maincolor">
                        <div className=" container-fluid">
                            <div className=" container-fluid px-0">
                                <div className="col-12 mt-0 text-center">
                                    <div className="col-12  ">
                                        <div className=" row ">
                                            <div className="card col-12 px-3 pt-1 pb-31 h-75 borderer text-start">
                                                <div className="row">
                                                    <div className="nav1 col-xl-12 pt-3 cardheader">
                                                        <h6>Latest Customers</h6>
                                                    </div>
                                                    <div className="text-start px-3">
                                                        <div>
                                                            <SentMessageTable messages={messages} />
                                                        </div> 
                                                    </div>   
                                                </div>                                                     
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>       
                    </div>
                </div>
                
                <Footer />
            </main>
            
            

        </>
    )
}

export default Dashboard ;