//Pending Table
import React from "react";
import { useState, useEffect } from "react";
//table components
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



//test data for table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#596CFF",
        fontWeight: "bold",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  

//end test table data



const TotalFaildMessages = (props) => {

    //set messages
    const [messages, setMessages] = useState();
    const [dataErrors, setDataErrors] = useState({});

    useEffect(()=> {

        //test preview
        const fetchData = async () => {
            // get the data from the api
            const dataResponse = await fetch(`http://bpodistributions.click:3010/messages/allfail_messages`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                }
              })
            // convert data to json
            const json = await dataResponse.json();
            console.log(json)
            setMessages(json);

        }
        fetchData()
        

        
    },[dataErrors]); 
    
    
    

    return (
        <>  
           
            <div className='pb-2 pt-2 tableoverflow tablesize-lg'>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell>id</StyledTableCell>
                            <StyledTableCell>Account No.</StyledTableCell>
                            <StyledTableCell align="middle">Customer Name</StyledTableCell>
                            <StyledTableCell align="middle">Customer Phone Number</StyledTableCell>
                            <StyledTableCell align="middle">Customer Kawasan Sewaan</StyledTableCell>
                            <StyledTableCell align="middle">Template</StyledTableCell>
                            
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {messages?.map(item => (
                            <StyledTableRow key={item.customer_id}>
                            <StyledTableCell>{item.id}</StyledTableCell>
                            <StyledTableCell>
                                {item.no_akaun_air}
                            </StyledTableCell>
                            <StyledTableCell align="middle">{item.nama}</StyledTableCell>
                            <StyledTableCell align="middle">{item.no_telefon_1}</StyledTableCell>
                            <StyledTableCell align="middle">{item.kawasan_sewaan}</StyledTableCell>
                            {
                              item.template === 'perumahan' ? 
                              <StyledTableCell align="middle"><i className="btn btn-warning">{item.template}</i></StyledTableCell>
                              :
                              <StyledTableCell align="middle"><i className="btn btn-info">{item.template}</i></StyledTableCell>
                            }
                            
                            
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            
                
            </div>
            
            
        </>
        
    )
}

export default TotalFaildMessages;
