import React, { useState } from 'react';
import Navbar from "../componenets/Navbar";
import Sidebar from "../componenets/Sidebar";
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';

//import select files
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import { InputLabel, NativeSelect, Select, OutlinedInput, MenuItem } from '@mui/material';

import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(./assets/img/logo-HS)',
    backgroundSize: 'cover',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const area_name_list = [
    'KOMPLEKS TUN SAMBANTHAN (PINES) BAZAR',
      'MEDAN SELERA PENJAJA TAMAN SETIAWANGSA',
      'MEDAN SELERA SEKSYEN 4 WANGSA MAJU',
      'PP TAMAN BOTANI, JALAN CENDERAWASIH',
      'PP TEMPAT LETAK KERETA JLN BERHALA',
      'PUSAT PENJAJA LOT PT 432 (D\'TASEK)',
      'PUSAT PENJAJA PARK & RIDE SUNGAI BESI',
      'ANJUNG SELERA, JALAN JUJUR',
      'MEDAN SELERA TAMAN SUNGAI BESI',
      'PASAR BERBANGUNAN KERAMAT MALL',
      'PASAR BERBANGUNAN UTC SENTUL',
      'PASAR BESAR TAMAN TUN DR ISMAIL',
      'PASAR DAN PP JLN WIRAWATI KG PANDAN',
      'PASAR DAN PP WANGSA MAJU SEKYEN 2',
      'PASAR DAN PUSAT PENJAJA TMN SERI SENTOSA',
      'PASAR HARIAN SELAYANG KUALA LUMPUR',
      'PASAR SUNGAI BESI',
      'PP LITTLE INDIA II (KEDAI BUNGA)',
      'PUSAT PENJAJA AIR PANAS',
      'PUSAT PENJAJA BANDAR SERI PERMAISURI',
      'PUSAT PENJAJA BAZARIA PANTAI',
      'PUSAT PENJAJA DANAU KOTA',
      'PUSAT PENJAJA D\'LAMAN RASA PEKAN SG BESI',
      'PUSAT PENJAJA D\'REJANG',
      'PUSAT PENJAJA JALAN DUTA',
      'PUSAT PENJAJA JALAN JEJAKA MALURI',
      'PUSAT PENJAJA JALAN LOBAK',
      'PUSAT PENJAJA JALAN MEGA MENDUNG',
      'PUSAT PENJAJA JALAN USAHAWAN',
      'PUSAT PENJAJA METRO PRIMA',
      'PUSAT PENJAJA PT80',
      'PUSAT PENJAJA PUTRA RIA',
      'PUSAT PENJAJA SELERA MEDAN BUNUS',
      'PUSAT PENJAJA SRI ANGKASA JAYA',
      'PUSAT PENJAJA TPCA, JALAN RAJA MUDA',
      'PUSAT SEMBELIHAN AYAM SEMENTARA',
      'PA SRI PULAU PINANG',
      'PA SRI SABAH 3A',
      'PA SRI SABAH 3B',
      'PA SRI JOHOR 4A',
      'PA SRI JOHOR 4B',
      'PA SRI JOHOR 4C',
      'PA SRI MELAKA (4D)',
      'PA SERI MELAKA (4F)',
      'PA SERI MELAKA (4G)',
      'PA SRI LABUAN',
      'PA SRI KOTA',
      'PPR DESA TUN RAZAK',
      'PPR TAMAN MULIA',
      'PPR DESA PETALING',
      'PPR RAYA PERMAI',
      'PPR PUDU ULU',
      'PPR JALAN COCHCRANE (LAKSAMANA)',
      'PPR COCHRANE PERKASA',
      'PPR SRI MALAYSIA',
      'PA (S) IKAN EMAS 1',
      'PA (S) IKAN EMAS 2',
      'PA JLN LOKE YEW',
      'PA LOKE YEW 2',
      'PA LOKE YEW 3',
      'PA LOKE YEW 4',
      'PA JLN. HANG TUAH',
      'PA SRI SELANGOR 1',
      'PA SRI SELANGOR 2',
      'KOMPLEKS SAN PENG',
      'PA SRI SARAWAK',
      'PA KG. KERINCHI 1A',
      'PPR SERI PANTAI',
      'PPR SRI ANGGERIK',
      'PPR PANTAI RIA',
      'PPR SRI CEMPAKA.',
      'PPR SRI ALAM',
      'PPR KG.MUHIBBAH',
      'PPR SALAK SELATAN',
      'PPR KG LIMAU',
      'PPR KERINCI LEMBAH PANTAI',
      'PPR BUKIT JALIL',
      'PPR BUKIT JALIL BLOK L&M',
      'LOT BANGLO KAYU KERINCHI',
      'KUARTERS PANTAI PERMAI',
      'KUARTER KEPONG',
      'PA SRI TRENGGANU 1',
      'PA SRI KELANTAN C',
      'PA SRI PANGKOR 1',
      'PA SRI NEGRI SEMBILAN',
      'PA SRI PERAK',
      'PPR MTEN BERINGIN',
      'PPR TAMAN WAHYU',
      'PPR MTEN PEKAN BATU',
      'PPR MTEN KG. BATU MUDA',
      'PPR MTEN INTAN BAIDURI',
      'SENTUL UTARA (KS)',
      'PA SRI KEDAH',
      'PA SRI TIOMAN I',
      'PA SETAPAK JAYA 1A',
      'PA SRI PERLIS 2',
      'PA SRI PERLIS 1',
      'PA DESA REJANG',
      'PPR JELATEK',
      'PPR KG.BARU AIR PANAS',
      'PPR SG BONUS',
      'PPR WANGSA SARI',
      'PPR GOMBAK SETIA',
      'PPR HILIRAN AMPANG',
      'PA GOMBAK MODEN 3B',
];

async function SignUpUser(credentials) {
  return fetch('http://bpodistributions.click:3010/customers/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
      
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
  
 }

 function AddCustomerSingle() {
  const classes = useStyles();
  const [account_no, setAccount_no] = useState();
  const [unit_no, setUnit_no] = useState();
  const [area, setArea] = useState([]);
  const [customername, setCustomerName] = useState();
  const [telephone1, setTelephone1] = useState();
  const [telephone2, setTelephone2] = useState();
  const [area_name, setArea_name] = useState();
  const [selectedscript, setSelectedscript] = useState();


  const penjaja = [
    'KOMPLEKS TUN SAMBANTHAN (PINES) BAZAR',
    'MEDAN SELERA PENJAJA TAMAN SETIAWANGSA',
    'MEDAN SELERA SEKSYEN 4 WANGSA MAJU',
    'PP TAMAN BOTANI, JALAN CENDERAWASIH',
    'PP TEMPAT LETAK KERETA JLN BERHALA',
    'PUSAT PENJAJA LOT PT 432 (D\'TASEK)',
    'PUSAT PENJAJA PARK & RIDE SUNGAI BESI',
    'ANJUNG SELERA, JALAN JUJUR',
    'MEDAN SELERA TAMAN SUNGAI BESI',
    'PASAR BERBANGUNAN KERAMAT MALL',
    'PASAR BERBANGUNAN UTC SENTUL',
    'PASAR BESAR TAMAN TUN DR ISMAIL',
    'PASAR DAN PP JLN WIRAWATI KG PANDAN',
    'PASAR DAN PP WANGSA MAJU SEKYEN 2',
    'PASAR DAN PUSAT PENJAJA TMN SERI SENTOSA',
    'PASAR HARIAN SELAYANG KUALA LUMPUR',
    'PASAR SUNGAI BESI',
    'PP LITTLE INDIA II (KEDAI BUNGA)',
    'PUSAT PENJAJA AIR PANAS',
    'PUSAT PENJAJA BANDAR SERI PERMAISURI',
    'PUSAT PENJAJA BAZARIA PANTAI',
    'PUSAT PENJAJA DANAU KOTA',
    'PUSAT PENJAJA D\'LAMAN RASA PEKAN SG BESI',
    'PUSAT PENJAJA D\'REJANG',
    'PUSAT PENJAJA JALAN DUTA',
    'PUSAT PENJAJA JALAN JEJAKA MALURI',
    'PUSAT PENJAJA JALAN LOBAK',
    'PUSAT PENJAJA JALAN MEGA MENDUNG',
    'PUSAT PENJAJA JALAN USAHAWAN',
    'PUSAT PENJAJA METRO PRIMA',
    'PUSAT PENJAJA PT80',
    'PUSAT PENJAJA PUTRA RIA',
    'PUSAT PENJAJA SELERA MEDAN BUNUS',
    'PUSAT PENJAJA SRI ANGKASA JAYA',
    'PUSAT PENJAJA TPCA, JALAN RAJA MUDA',
    'PUSAT SEMBELIHAN AYAM SEMENTARA',
   
  ];
  const perumahan = [
    'PA SRI PULAU PINANG',
    'PA SRI SABAH 3A',
    'PA SRI SABAH 3B',
    'PA SRI JOHOR 4A',
    'PA SRI JOHOR 4B',
    'PA SRI JOHOR 4C',
    'PA SRI MELAKA (4D)',
    'PA SERI MELAKA (4F)',
    'PA SERI MELAKA (4G)',
    'PA SRI LABUAN',
    'PA SRI KOTA',
    'PPR DESA TUN RAZAK',
    'PPR TAMAN MULIA',
    'PPR DESA PETALING',
    'PPR RAYA PERMAI',
    'PPR PUDU ULU',
    'PPR JALAN COCHCRANE (LAKSAMANA)',
    'PPR COCHRANE PERKASA',
    'PPR SRI MALAYSIA',
    'PA (S) IKAN EMAS 1',
    'PA (S) IKAN EMAS 2',
    'PA JLN LOKE YEW',
    'PA LOKE YEW 2',
    'PA LOKE YEW 3',
    'PA LOKE YEW 4',
    'PA JLN. HANG TUAH',
    'PA SRI SELANGOR 1',
    'PA SRI SELANGOR 2',
    'KOMPLEKS SAN PENG',
    'PA SRI SARAWAK',
    'PA KG. KERINCHI 1A',
    'PPR SERI PANTAI',
    'PPR SRI ANGGERIK',
    'PPR PANTAI RIA',
    'PPR SRI CEMPAKA.',
    'PPR SRI ALAM',
    'PPR KG.MUHIBBAH',
    'PPR SALAK SELATAN',
    'PPR KG LIMAU',
    'PPR KERINCI LEMBAH PANTAI',
    'PPR BUKIT JALIL',
    'PPR BUKIT JALIL BLOK L&M',
    'LOT BANGLO KAYU KERINCHI',
    'KUARTERS PANTAI PERMAI',
    'KUARTER KEPONG',
    'PA SRI TRENGGANU 1',
    'PA SRI KELANTAN C',
    'PA SRI PANGKOR 1',
    'PA SRI NEGRI SEMBILAN',
    'PA SRI PERAK',
    'PPR MTEN BERINGIN',
    'PPR TAMAN WAHYU',
    'PPR MTEN PEKAN BATU',
    'PPR MTEN KG. BATU MUDA',
    'PPR MTEN INTAN BAIDURI',
    'SENTUL UTARA (KS)',
    'PA SRI KEDAH',
    'PA SRI TIOMAN I',
    'PA SETAPAK JAYA 1A',
    'PA SRI PERLIS 2',
    'PA SRI PERLIS 1',
    'PA DESA REJANG',
    'PPR JELATEK',
    'PPR KG.BARU AIR PANAS',
    'PPR SG BONUS',
    'PPR WANGSA SARI',
    'PPR GOMBAK SETIA',
    'PPR HILIRAN AMPANG',
    'PA GOMBAK MODEN 3B',

  ]


  async function findCustomerLocation(e) {
    console.log(e)
    if(e.target.value){
        document.getElementById('search-table-area').style.display = "block"
    }
    
    if(!e.target.value){
        document.getElementById('search-table-area').style.display = "none"
    }
    
    const location = e.target.value
    console.log(location);
    return fetch('http://bpodistributions.click:3010/customers/get_all_customer_by_location', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({location})
    })
    .then(response => response.json())
    .then(responseJson => {
      console.log(responseJson);
      setArea(responseJson)
    })
    
   }

  //select area
  async function findScript(req){
    console.log(req.target.value)
    setSelectedscript(req.target.value)
    if(req.target.value === 'penjaja'){
      document.getElementById('penjaja').style.display = "block"
      document.getElementById('perumahan').style.display = "none"
    }
    else{
      document.getElementById('perumahan').style.display = "block"
      document.getElementById('penjaja').style.display = "none"
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await SignUpUser({
      account_no,
      unit_no, 
      customername,
      telephone1,
      telephone2,
      area_name,  
      selectedscript
    });
    console.log(response);
    if ('message' in response) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      })
      .then((value) => {
        window.location.href = "/";
      });
    } else {
      swal("Failed", response.error, "error");
    }
      
    
  }
    return(
        <>
            <Navbar />
            <div className="row">
             <Sidebar />
            </div> 
            <main className="main-content position-relative h-100 border-radius-md contained ">
                <div className="container-fluid g-3 ">
                  <h4 className="colorfix sizefix mx-2">Add Customer</h4>
                  <div className="card px-2 pt-3 pb-3 mb-4 cardbg">
                    <div className="container-fluid" style={{backgroundColor:"white"}}>
                      <div className="container-fluid px-0 ">
                          <div className="col-12">
                                          <div className="col-6" >
                                            <form className={classes.form} noValidate onSubmit={handleSubmit}>
                                              <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="accouont_no"
                                                name="email"
                                                label="Account No."
                                                onChange={e => setAccount_no(e.target.value)}
                                              />
                                              <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="unit_no"
                                                name="unit_no"
                                                label="Unit No."
                                                type="text"
                                                onChange={e => setUnit_no(e.target.value)}
                                              />
                                              <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="customername"
                                                name="customername"
                                                label="Customer Name"
                                                type="text"
                                                onChange={e => setCustomerName(e.target.value)}
                                              />
                                              <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="telephone1"
                                                name="telephone1"
                                                label="Telephone No. 1"
                                                type="text"
                                                onChange={e => setTelephone1(e.target.value)}
                                              />
                                              <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="telephone2"
                                                name="telephone2"
                                                label="Telephone No. 2"
                                                type="text"
                                                onChange={e => setTelephone2(e.target.value)}
                                              />
                                              <div className="col-5 mt-2">
                                                  <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Choose Script</InputLabel>
                                                        <Select
                                                          labelId="demo-simple-select-label"
                                                          id="demo-simple-select"
                                                          label="Select Area"
                                                          onChange={findScript}
                                                          
                                                        >
                                                          <MenuItem value={'penjaja'}>Penjaja</MenuItem> 
                                                          <MenuItem value={'perumahan'}>Perumahan</MenuItem>
                                                        </Select>
                                                  </FormControl>
                                                </div>

                                                <div className="col-8 mt-2 mx-2"  id="perumahan" style={{display: "none"}}>
                                                <FormControl fullWidth>
                                                      <select
                                                              labelId="demo-simple-select-label"
                                                              id="demo-simple-select"
                                                              className='form-select form-select-lg mb-3'
                                                              value={area_name}
                                                              label="Age"
                                                              onChange={e => setArea_name(e.target.value)}
                                                          >
                                                              {perumahan.map((name) => (
                                                                  <option
                                                                  
                                                                  value={name}
                                                                  
                                                                  >
                                                                  {name}
                                                                  </option>
                                                              ))}
                                                          </select>
                                                      
                                                </FormControl>

                                                </div>

                                                <div className="col-8 mt-2"  id="penjaja" style={{display: "none"}}>
                                                  <FormControl fullWidth>
                                                        
                                                        <select
                                                              labelId="demo-simple-select-label"
                                                              id="demo-simple-select"
                                                              className='form-select form-select-lg mb-3'
                                                              value={area_name}
                                                              label="Area Name"
                                                              onChange={e => setArea_name(e.target.value)}
                                                          >
                                                              {penjaja.map((name) => (
                                                                  <option
                                                                  
                                                                  value={name}
                                                                  
                                                                  >
                                                                  {name}
                                                                  </option>
                                                              ))}
                                                          </select>
                                                        
                                                  </FormControl>
                                                </div>

                                            {/* <InputLabel id="demo-simple-select-label">Area Name</InputLabel>
                                            <select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                className='form-select form-select-lg mb-3'
                                                value={area_name}
                                                label="Age"
                                                onChange={e => setArea_name(e.target.value)}
                                            >
                                                {area_name_list.map((name) => (
                                                    <option
                                                    
                                                    value={name}
                                                    
                                                    >
                                                    {name}
                                                    </option>
                                                ))}
                                            </select> */}
                                            
                                              <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className={classes.submit}
                                              >
                                                Add Customer
                                              </Button>
                                            </form>
                                          </div> 
                          </div>
                      </div>
                    </div>
                  </div>
                </div>   
            </main>
        </>
    )
}

export default AddCustomerSingle ;