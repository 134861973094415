import React from "react";
import Navbar from "../componenets/Navbar";
import Sidebar from "../componenets/Sidebar";
import { useState, useEffect } from "react";
import { read, utils, writeFile } from 'xlsx';
import CurrencyFormat from 'react-currency-format';
import SentMessageTable from './components/SentMessagesTable';

import PaidIcon from '@mui/icons-material/Paid';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';

import Footer from "./components/Footer";
import { Button } from "@mui/material";

let initialvalues = {
    allmessages: '',
    failedmessages: '',
    sendmessages:'',
    archivemessages: ''
 }

 const AdminDashboard = () =>{
    const [data, setData] = useState(initialvalues);
    const [messages, setMessages] = useState();
    const [dataErrors, setDataErrors] = useState({});

    useEffect(()=> {

        //test preview
        const fetchData = async () => {
            // get the data from the api
            const dataResponse = await fetch(`http://bpodistributions.click:3010/messages/get_messages_values`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                }
              })
            // convert data to json
            const json = await dataResponse.json();
            console.log(json.length)
            initialvalues = {
                allmessages: json.allmessages,
                failedmessages: json.failedmessages,
                sendmessages:json.sendmessages,
                archivemessages: json.archivemessages,
                total_due_amount: json.total_due_amount || 0,
                current_amount: json.current_amount || 0,
                arrears_amount: json.arrears_amount || 0

            }
            setData(initialvalues)
            setMessages(json);
        }
        fetchData()
        

        
    },[dataErrors]); 
    
    const SentMessages = async(req, res) => {

        const dataResponse = await fetch(`http://bpodistributions.click:3010/reports/all_sent_messages_report`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                }
              })
            // convert data to json
            const json = await dataResponse.json();
          //XLSX conversion

          var wb = utils.book_new();
          var ws = utils.json_to_sheet(json);
          
          utils.book_append_sheet(wb, ws, "MySheet1");

          writeFile(wb, "sentMessages.xlsx");
    } 

    const FailedMessages = async(req, res) => {

        const dataResponse = await fetch(`http://bpodistributions.click:3010/reports/all_failed_messages_report`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                }
              })
            // convert data to json
            const json = await dataResponse.json();
          //XLSX conversion

          var wb = utils.book_new();
          var ws = utils.json_to_sheet(json);
          
          utils.book_append_sheet(wb, ws, "MySheet1");

          writeFile(wb, "failedMessages.xlsx");
    } 

    const RemainingMessages =   async(req, res) => {

        const dataResponse = await fetch(`http://bpodistributions.click:3010/reports/all_remaining_messages_report`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                }
              })
            // convert data to json
            const json = await dataResponse.json();
            
          console.log(json)
          //XLSX conversion

          var wb = utils.book_new();
          var ws = utils.json_to_sheet(json);
          
          utils.book_append_sheet(wb, ws, "MySheet1");

          writeFile(wb, "remainingMessages.xlsx");
    } 

    return(
        <>
            <Navbar />
            <Sidebar />
            <main className="main-content position-relative h-100 border-radius-md contained">
                <div className="container-fluid g-2">
                <h4 className=' mx-4 mb-3' style={{fontSize:'25px', color: 'black'}}>Dashboard</h4>
                    <div className="card pt-4 pb-3 mb-4 maincolor">
                        <div className=" container-fluid">
                            <div className=" container-fluid px-0">
                                <div className="col-12 mt-0 text-center">
                                    <div className="col-12  ">
                                        <div className=" row ">
                                            <div className=" card mb-4   ">
                                                <div className=" row px-0 mt-4 mb-4 justify-content-center gx-0 colllgap">
                                                    <div className=" col-lg-3 boxShadow">
                                                        <div className=" card move-on-hover h-100" style={{borderRadius:'30px'}}>
                                                            <div className="card-header text-center text-bold "style={{fontSize:"18px", borderRadius:'30px 30px 0px 0px', padding: '5px'}}>Total Messages</div>
                                                            <div className='createdicon'><i className="fa-solid fa-layer-group fa-4x" style={{color: "#2F80ED"}}></i></div>
                                                            <div className="body text-center text-bolder" style={{ fontSize: '25px', color: '#2F80ED', padding: '10px'}}>{data.allmessages}</div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 boxShadow ">
                                                        <div className="card move-on-hover h-100" style={{borderRadius:'30px'}}>
                                                            <div className="card-header text-center text-bold " style={{fontSize:"18px", borderRadius:'30px 30px 0px 0px', padding: '5px'}}>Sent Messages</div>
                                                            <div className='createdicon'><i className="fas fa-check-square fa-4x" style={{color: "green"}}></i></div>
                                                            <div className="body text-center text-bolder " style={{ fontSize: '25px', color: 'green', padding: '10px'}}>{data.sendmessages}
                                                            <br />
                                                            <Button variant="contained" size="small" onClick={SentMessages}> download</Button>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 boxShadow ">
                                                        <div className="card move-on-hover h-100" style={{borderRadius:'30px'}}>
                                                            <div className="card-header text-center text-bold "style={{fontSize:"18px", borderRadius:'30px 30px 0px 0px', padding: '5px'}}>Failed Messages</div>
                                                            <div className='createdicon'><i className="fas fa-exclamation-triangle fa-4x" style={{color: "red"}}></i></div>
                                                            <div className="body text-center text-bolder " style={{ fontSize: '25px', color:'red', padding: '10px'}}>{data.failedmessages}
                                                            <br />
                                                            <Button variant="contained" size="small" onClick={FailedMessages}> download</Button>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 boxShadow ">
                                                        <div className="card move-on-hover h-100" style={{borderRadius:'30px'}}>
                                                            <div className="card-header text-center text-bold "style={{fontSize:"18px", borderRadius:'30px 30px 0px 0px', padding: '5px'}}>Remaining Messages</div>
                                                            <div className='createdicon'><i className="fas fa-sticky-note fa-4x" style={{color: "#F6BE00"}}></i></div>
                                                            <div className="body text-center text-bolder " style={{ fontSize: '25px', color:'#F6BE00', padding: '10px'}}>{data.archivemessages}
                                                            <br />
                                                            <Button variant="contained" size="small" onClick={RemainingMessages}> download</Button>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    

                                                </div>
                                                <div className="bg-gray-200 container" style={{height: "3%", width: "100%", marginRight: "3%"}}>
                                                    <h1></h1>
                                                </div>
                                                {/* Amount Cards */}
                                                <div className=" row px-0 mt-4 mb-4 justify-content-center gx-0 colllgap">
                                                    <div className=" col-lg-4 boxShadow">
                                                        <div className=" card move-on-hover h-100" style={{borderRadius:'30px'}}>
                                                            <div className="card-header text-center text-bold "style={{fontSize:"18px", borderRadius:'30px 30px 0px 0px', padding: '5px'}}>Total Due Amount</div>
                                                            <div className='createdicon'><PaidIcon style={{color: "green", fontSize: "5em"}}/></div>
                                                            <div className="body text-center text-bolder" style={{ fontSize: '25px', color: 'green', padding: '10px'}}>
                                                            <CurrencyFormat value={data.current_amount + data.arrears_amount} displayType={'text'} thousandSeparator={true} prefix={'RM '} /> 
                                                            <br />
                                                            <Button variant="contained" size="small" onClick={RemainingMessages}> download</Button></div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 boxShadow ">
                                                        <div className="card move-on-hover h-100" style={{borderRadius:'30px'}}>
                                                            <div className="card-header text-center text-bold " style={{fontSize:"18px", borderRadius:'30px 30px 0px 0px', padding: '5px'}}>Total Current Amount</div>
                                                            <div className='createdicon'><CurrencyExchangeRoundedIcon style={{color: "#2F80ED", fontSize: "5em"}}/></div>
                                                            <div className="body text-center text-bolder " style={{ fontSize: '25px', color: '#2F80ED', padding: '10px'}}>
                                                            <CurrencyFormat value={data.current_amount} displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                                                            <br />
                                                            <Button variant="contained" size="small" onClick={RemainingMessages}> download</Button>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 boxShadow ">
                                                        <div className="card move-on-hover h-100" style={{borderRadius:'30px'}}>
                                                            <div className="card-header text-center text-bold "style={{fontSize:"18px", borderRadius:'30px 30px 0px 0px', padding: '5px'}}>Total Arrears Amount</div>
                                                            <div className='createdicon'><PaidIcon style={{color: "red", fontSize: "5em"}}/></div>
                                                            <div className="body text-center text-bolder " style={{fontSize: '25px', color:'red', padding: '10px'}}>
                                                            <CurrencyFormat value={data.arrears_amount} style={{backgroundColor: "#f5f5f5"}} displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                                                            <br />
                                                            <Button variant="contained" size="small" onClick={RemainingMessages}> download</Button>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="card col-12 px-3 pt-1 pb-31 h-75 borderer text-start">
                                                <div className="row">
                                                    <div className="nav1 col-xl-12 pt-3 pb-3 cardheader">
                                                        <h6>Latest Customers</h6>
                                                    </div>
                                                    <div className="text-start px-3 pt-2">
                                                        <div>
                                                            <SentMessageTable messages={messages} />
                                                        </div> 
                                                    </div>   
                                                </div>                                                     
                                            </div>
                                            
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            </div> 
                        </div>       
                    </div>
                </div>
                
                <Footer />
            </main>
        </>
    )
}

export default AdminDashboard ;