import Navbar from "../componenets/Navbar";
import Sidebar from "../componenets/Sidebar";
import React, { useState, useEffect}  from 'react';
import TotalSentMessages from "../admin/components/TotalMessages";
import AllcustomersTable from "../admin/components/AllcustomersTable";
import { useNavigate } from "react-router-dom";

import swal from "sweetalert";
import axios from "axios";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

//test data for table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#596CFF",
        fontWeight: "bold",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  

const penjaja = [
    'PASAR HARIAN SELAYANG KUALA LUMPUR',
'PASAR BERBANGUNAN UTC SENTUL',
'PUSAT PENJAJA DANAU KOTA',
'MEDAN SELERA SEK 4 WANGSA MAJU',
'PASAR WANGSA MAJU SEK. 2',
'PUSAT PENJAJA JALAN DUTA',
'PASAR BESAR TAMAN TUN DR ISMAIL',
'PUSAT PENJAJA METRO PRIMA',
'MEDAN SELERA PENJAJA TAMAN SETIAWANGSA',
'PUSAT PENJAJA D REJANG',
'PUSAT PENJAJA JALAN USAHAWAN',
'MEDAN SELERA D TASIK',
'PUSAT PENJAJA AIR PANAS',
'PP SEMENTARA TAMAN KEJIRANAN AIR PANAS',
'PASAR BERBANGUNAN KERAMAT MALL',
'KIOSK LAMAN SEJAHTERA PPR SG BONUS',
'PUSAT PENJAJA JALAN JEJAKA MALURI',
'PASAR DAN PP JLN WIRAWATI KG PANDAN',
'PUSAT PENJAJA TPCA JALAN RAJA MUDA',
'PUSAT SEMBELIHAN AYAM SEMENTARA',
'MEDAN SELERA DAMAI JALAN BATU BATA',
'PUSAT PENJAJA BANDAR SERI PERMAISURI',
'ANJUNG SELERA JALAN JUJUR',
'PASAR SG BESI',
'PP PARK & RIDE SG BESI',
'PUSAT KOMUNITI BANDAR TUN RAZAK',
'PUSAT PENJAJA D LAMAN RASA PEKAN SG BESI',
'MEDAN SELERA TAMAN SUNGAI BESI',
'PUSAT PENJAJA DESA TUN RAZAK',
'PUSAT PENJAJA JALAN LOBAK',
'PASAR DAN PUSAT PENJAJA RAZAK MANSION',
'PUSAT PENJAJA JALAN MEGA MENDUNG',
'PASAR DAN PP KG BARU SALAK SELATAN',
'PASAR DAN PUSAT PENJAJA JALAN KLANG LAMA',
'PASAR DAN PUSAT PENJAJA TMN SERI SENTOSA',
'PUSAT PENJAJA PUTRA RIA',
'PUSAT PENJAJA BAZARIA PANTAI',
'PUSAT PENJAJA SRI ANGKASA JAYA',
'PP TAMAN BOTANI JALAN CENDERAWASIH',
'PP LITTLE INDIA',
'PP TEMPAT LETAK KERETA JLN BERHALA',
'PUSAT PENJAJA PT80',
'PUSAT PENJAJA SELERA MEDAN BUNUS',
'KOMPLEKS TUN SAMBATHAN (PINES BAZAR)',
'PP LORONG ANTARA 495 ATAU 497 JLN TAR',
'MEDAN SELERA D TEBING RIVER OF LIFE',
  ];
  const perumahan = [
    'PA SRI PULAU PINANG',
    'PA SRI SABAH 3A',
    'PA SRI SABAH 3B',
    'PA SRI JOHOR 4A',
    'PA SRI JOHOR 4B',
    'PA SRI JOHOR 4C',
    'PA SRI MELAKA (4D)',
    'PA SERI MELAKA (4F)',
    'PA SERI MELAKA (4G)',
    'PA SRI LABUAN',
    'PA SRI KOTA',
    'PPR DESA TUN RAZAK',
    'PPR TAMAN MULIA',
    'PPR DESA PETALING',
    'PPR RAYA PERMAI',
    'PPR PUDU ULU',
    'PPR JALAN COCHCRANE (LAKSAMANA)',
    'PPR COCHRANE PERKASA',
    'PPR SRI MALAYSIA',
    'PA (S) IKAN EMAS 1',
    'PA (S) IKAN EMAS 2',
    'PA JLN LOKE YEW',
    'PA LOKE YEW 2',
    'PA LOKE YEW 3',
    'PA LOKE YEW 4',
    'PA JLN. HANG TUAH',
    'PA SRI SELANGOR 1',
    'PA SRI SELANGOR 2',
    'KOMPLEKS SAN PENG',
    'PA SRI SARAWAK',
    'PA KG. KERINCHI 1A',
    'PPR SERI PANTAI',
    'PPR SRI ANGGERIK',
    'PPR PANTAI RIA',
    'PPR SRI CEMPAKA.',
    'PPR SRI ALAM',
    'PPR KG.MUHIBBAH',
    'PPR SALAK SELATAN',
    'PPR KG LIMAU',
    'PPR KERINCI LEMBAH PANTAI',
    'PPR BUKIT JALIL',
    'PPR BUKIT JALIL BLOK L&M',
    'LOT BANGLO KAYU KERINCHI',
    'KUARTERS PANTAI PERMAI',
    'KUARTER KEPONG',
    'PA SRI TRENGGANU 1',
    'PA SRI KELANTAN C',
    'PA SRI PANGKOR 1',
    'PA SRI NEGRI SEMBILAN',
    'PA SRI PERAK',
    'PPR MTEN BERINGIN',
    'PPR TAMAN WAHYU',
    'PPR MTEN PEKAN BATU',
    'PPR MTEN KG. BATU MUDA',
    'PPR MTEN INTAN BAIDURI',
    'SENTUL UTARA (KS)',
    'PA SRI KEDAH',
    'PA SRI TIOMAN I',
    'PA SETAPAK JAYA 1A',
    'PA SRI PERLIS 2',
    'PA SRI PERLIS 1',
    'PA DESA REJANG',
    'PPR JELATEK',
    'PPR KG.BARU AIR PANAS',
    'PPR SG BONUS',
    'PPR WANGSA SARI',
    'PPR GOMBAK SETIA',
    'PPR HILIRAN AMPANG',
    'PA GOMBAK MODEN 3B',

  ]
  

const Followup = () => {
    const [customers, setCustomers] = useState([]);
    const [area, setArea] = useState([]);
    const [script, setScript] = useState();

    const navigate = useNavigate();

    //select area outstanding
  async function findScriptOutstanding(req){
    console.log(req.target.value)
    if(req.target.value === 'penjaja_followup'){
      document.getElementById('penjaja_followup').style.display = "block"
      document.getElementById('perumahan_followup').style.display = "none"
      setScript(req.target.value)
    }
    else{
      document.getElementById('perumahan_followup').style.display = "block"
      document.getElementById('penjaja_followup').style.display = "none"
      setScript(req.target.value)
    }
  }

   //find customers by area
   async function findfollowupCustomerLocation(req) {
    console.log(req.target.value)
    if(req.target.value){
        document.getElementById('search-table-area_followup').style.display = "block"
    }
    
    if(!req.target.value){
        document.getElementById('search-table-area_followup').style.display = "none"
    }
    
    const location = req.target.value
    return fetch('http://bpodistributions.click:3010/customers/get_followup_customer_by_location', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({location})
    })
    .then(response => response.json())
    .then(responseJson => {
      console.log(responseJson);
      setArea(responseJson)
    })
    
   }

   //find outstanding customer 
   async function findFollowupCustomer(req) {
    console.log(req.target.value)
    if(req.target.value){
        document.getElementById('search-table-followup').style.display = "block"
    }
    
    if(!req.target.value){
        document.getElementById('search-table-followup').style.display = "none"
    }
    
    const account_no = req.target.value
    return fetch('http://bpodistributions.click:3010/customers/get_followup_customer', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({account_no})
    })
    .then(response => response.json())
    .then(responseJson => {
      console.log(responseJson);
      setCustomers(responseJson)
    })
    
   }
  
   async function sendOutstandingMessage(id,bill_id, name,
    no_akaun_air, no_telefon_1, no_telefon_2, kawasan_sewaan, 
    current_amount, arrears_amount, total_due_amount, due_date, script, username
    ) {
  await axios
      .post("http://bpodistributions.click:3010/messages/send_outstanding_bill_single", {
        id:id,
        bill_id: bill_id,
        name: name,
        account_no: no_akaun_air,
        phone_no_1: no_telefon_1,
        phone_no_2: no_telefon_2,
        kawasan_sewaan: kawasan_sewaan,
        current_amount:current_amount,
        arrears_amount:arrears_amount,
        total_due_amount:total_due_amount,
        due_date: due_date,
        script: script,
        username: username
      })
      .then((response) => {
        console.log(response.data)
        if('success' in response.data){
          swal("Success", "Sent", "success", {
            buttons: false,
            timer: 2000,
          })
          setCustomers([])
          navigate("/messages");
        }else{
          swal("Failed", "Not Sent", "error", {
            buttons: false,
            timer: 2000,
          })
          setCustomers([])
          navigate("/messages");
        }
          
        
      });
    }

    //send outstanding messages
    async function sendFollowupMessageArea(item) {
        await axios
          .post("http://bpodistributions.click:3010/messages/send_bill_reminder", item)
          .then((response) => {
            console.log(response.data)
            if('success' in response.data){
              swal("Success", "Sent", "success", {
                buttons: false,
                timer: 2000,
              })
              document.getElementById(`followup-${item.id}`).style.display = 'none';
              navigate("/messages");
               
            }else{
              swal("Failed", "Not Sent", "error", {
                buttons: false,
                timer: 2000,
              })
              document.getElementById(item.id).style.display = 'none';
              navigate("/messages");
            }
              
            
          });
        }
      //end outstanding send messages



    return(
        <div className="container-fluid g-3 ">
                <h4 className="colorfix sizefix mx-2">Send Followup Messages</h4>
                    <div className="card px-2 pt-3 pb-3 mb-4 cardbg">
                        <div className="container-fluid" style={{backgroundColor:"white"}}>
                            <div className="container-fluid px-0 ">
                                <div className="col-12">
                                    <div className="container-fluid pb-4 pt-3 g-3">
                                        <div className="row ">

                                            <Box className="row" component="form" sx={{'& .MuiTextField-root': { m: 1, width: '40ch' }, }} noValidate autoComplete="off" >
                                                <div className="col-5">
                                                    <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="Search Customer by Account Number"
                                                    defaultValue=""
                                                    onChange={findFollowupCustomer}
                                                    />
                                                    
                                                </div>
                                                <div className="col-5 mt-2">
                                                  <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Choose Script</InputLabel>
                                                        <Select
                                                          labelId="demo-simple-select-label"
                                                          id="demo-simple-select"
                                                          label="Select Area"
                                                          onChange={findScriptOutstanding}
                                                        >
                                                          <MenuItem value={'penjaja_followup'}>Penjaja</MenuItem> 
                                                          <MenuItem value={'perumahan_followup'}>Perumahan</MenuItem>
                                                        </Select>
                                                  </FormControl>
                                                </div>
                                               
                                                <div className="col-8 mt-2 mx-2"  id="perumahan_followup" style={{display: "none"}}>
                                                <FormControl fullWidth>
                                                      <InputLabel id="demo-simple-select-label">Choose Location (Perumahan)</InputLabel>
                                                      <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Select Area"
                                                        onChange={findfollowupCustomerLocation}
                                                      >
                                                              {perumahan.map(item=>(
                                                                <MenuItem value={item}>{item}</MenuItem>
                                                              )

                                                              )}
                                                      </Select>
                                                </FormControl>

                                                </div>

                                                <div className="col-8 mt-2 mx-2"  id="penjaja_followup" style={{display: "none"}}>
                                                  <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Choose Location (Penjaja)</InputLabel>
                                                        <Select
                                                          labelId="demo-simple-select-label"
                                                          id="demo-simple-select"
                                                          label="Penjaja"
                                                          onChange={findfollowupCustomerLocation}
                                                        >
                                                              {penjaja.map(item=>(
                                                                <MenuItem value={item}>{item}</MenuItem>
                                                              )

                                                              )}
                                                                
                                                                
                                                        </Select>
                                                  </FormControl>
                                                </div>
                                                
                                                
                                            </Box>
                                            
                                                
                                            <div id="search-table-followup" style={{display: "none"}}>
                                                <legend><b>Followup Customer</b></legend>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                        <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>ID</StyledTableCell>
                                                            <StyledTableCell>NAMA</StyledTableCell>
                                                            <StyledTableCell align="center">NO. AKAUN AIR</StyledTableCell>
                                                            <StyledTableCell align="center">NO. TELEFON (1)</StyledTableCell>
                                                            <StyledTableCell align="center">NO. TELEFON (2)</StyledTableCell>
                                                            <StyledTableCell align="center">ALAMAT UNIT</StyledTableCell>
                                                            <StyledTableCell>CURRENT DUE AMOUNT</StyledTableCell>
                                                            <StyledTableCell>ARREAS AMOUNT</StyledTableCell>
                                                            <StyledTableCell>TOTAL DUE AMOUNT</StyledTableCell>
                                                            <StyledTableCell>DUE DATE</StyledTableCell>
                                                            <StyledTableCell>ACTIONS</StyledTableCell>
                                                        </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                        
                                                            <StyledTableRow key={customers?.customer_id}>
                                                            
                                                            
                                                            {customers?.current_amount ? (
                                                                    <>
                                                                      <StyledTableCell>{customers?.id}</StyledTableCell>
                                                                      <StyledTableCell>
                                                                          {customers?.nama}
                                                                      </StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.no_akaun_air}</StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.no_telefon_1}</StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.no_telefon_2}</StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.kawasan_sewaan}</StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.current_amount}</StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.arrears_amount}</StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.total_due_amount}</StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.due_date}</StyledTableCell>
                                                                      
                                                                      <StyledTableCell align="center">
                                                                  
                                                                      <button className="btn btn-primary" onClick={() => 
                                                                          sendOutstandingMessage(customers.id,customers.bill_id,customers.nama,customers.no_akaun_air,customers.no_telefon_1,
                                                                          customers.no_telefon_2, customers.kawasan_sewaan,customers.current_amount,
                                                                            customers.arrears_amount, customers.total_due_amount, customers.due_date, script, localStorage.getItem('username'))}>
                                                                          Send
                                                                      </button>
                                                                      </StyledTableCell>
                                                                    
                                                            
                                                                    </>
                                                                    
                                                                ):(
                                                                    <>
                                                                        <StyledTableCell align="center"></StyledTableCell>
                                                                        <StyledTableCell align="center"></StyledTableCell>
                                                                        <StyledTableCell align="center"></StyledTableCell>
                                                                        <StyledTableCell align="center"></StyledTableCell>
                                                                        <StyledTableCell align="center"></StyledTableCell>
                                                                        
                                                                        <StyledTableCell size="center" align="center">
                                                                         <h4>no user found</h4>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center"></StyledTableCell>
                                                                        <StyledTableCell size="center" align="center"></StyledTableCell>
                                                                        <StyledTableCell size="center" align="center"></StyledTableCell>
                                                                        <StyledTableCell size="center" align="center"></StyledTableCell>
                                                                        <StyledTableCell size="center" align="center"></StyledTableCell>
                                                                    </>
                                                                
                                                                )}
                                                            
                                                            </StyledTableRow>
                                                       
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                            
                                            <div id='search-table-area_followup' className="mt-3" style={{display: "none"}}>
                                           
                                            <legend><b>Total Followup Customers List by Area</b></legend>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                        <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>ID</StyledTableCell>
                                                            <StyledTableCell>NAMA</StyledTableCell>
                                                            <StyledTableCell align="center">NO. AKAUN AIR</StyledTableCell>
                                                            
                                                            <StyledTableCell align="center">KAWASAN SEWAAN</StyledTableCell>
                                                            <StyledTableCell align="center">NO. TELEFON</StyledTableCell>
                                                            <StyledTableCell>ACTIONS</StyledTableCell>
                                                        </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                        {area?.map(item => (
                                                          <>
                                                             {item.customer ?                                                          
                                                            <StyledTableRow key={item?.customer_id} id={`followup-${item?.id}`}>
                                                            <StyledTableCell>{item?.id}</StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.customer?.nama}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">{item?.customer?.no_akaun_air}</StyledTableCell>
                                                            <StyledTableCell align="center">{item?.customer?.kawasan_sewaan}</StyledTableCell>
                                                            <StyledTableCell align="center">{item?.customer?.no_telefon_1}</StyledTableCell>
                                                            
                                                            <StyledTableCell align="center">

                                                                <button className="btn btn-primary" onClick={() => 
                                                                    sendFollowupMessageArea(item)}>
                                                                      Send</button></StyledTableCell>
                                                            </StyledTableRow>
                                                            :
                                                            <>
                                                            </>
                                                              }
                                                          </>
                                                         
                                                        ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            
                                                
                                            </div>
                                            
                                            <br className="pt-4"/>
                                            <hr />
                                            {/* <AllcustomersTable /> */}
                                            
                                            {/* <TotalSentMessages /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default Followup;