import React from 'react';
//import './App.css';
import './test.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Signin from './login/Sigin';
import Signup from './login/Signup';
import Dashboard from './profiledetails/Dashboard';
import AddCustomer from './profiledetails/AddCustomer';
import SendMessage from './profiledetails/SendMessage';
import Messages from './profiledetails/Messages';
import AddCustomerSingle from './profiledetails/AddCustomerSingle';
import AdminDashboard from './admin/AdminDashboard';
import SendMessagesList from './profiledetails/SendMessagesList';
import FaildMessagesList from './profiledetails/FaildMessagesList';
import AddUser from './profiledetails/AddUser';
import AllCustomer from './profiledetails/AllCustomers';
import SingleCustomer from './profiledetails/SingleCustomer';
import EditCustomerSingle from './profiledetails/EditCustomer';

function App() {
  const token = localStorage.getItem('accessToken');
  const role = localStorage.getItem('role');
  localStorage.setItem('logo', 'http://www.bpodistributions.com/wp-content/uploads/2019/01/BPO-DISTRIBUTIONS-1.png')
  if(!token) {
    return(
      <BrowserRouter>
        <Routes>
          <Route path="/" >
            <Route path='/' element={<Signin />} />
            <Route path='/signup' element={<Signup />} />
          </Route>
        </Routes>
      </BrowserRouter>
    )
  }

  if(role === '0'){
    return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>
          <Route path="/" >
            {/* Profile Details route */}
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/add_customer" element={<AddCustomer />} />
              <Route path="/send_message" element={<SendMessage />} />
              <Route path='/sendmessages' element={<SendMessagesList />} />
              <Route path='/faildmessages' element={<FaildMessagesList />} />
              <Route path='/allcustomers' element={<AllCustomer /> } />
              <Route path='/singlecustomer' element={<SingleCustomer />} />
              {/* <Route path='/adduser' element={<AddUser />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>


    );
  }
  if(role === '1'){
    return (
      <div className="wrapper">
        <BrowserRouter>
          <Routes>
            <Route path="/" >
              {/* Profile Details route */}
              <Route path="/" element={<AdminDashboard />} />
              <Route path="/admin_dashboard" element={<AdminDashboard />} />
              <Route path="/add_customer" element={<AddCustomer />} />
              <Route path='/addcustomer_single' element={< AddCustomerSingle/>} />
              <Route path="/send_message" element={<SendMessage />} />
              <Route path="/messages" element={<Messages />} />
              <Route path="/editcustomer/:id" element={<EditCustomerSingle />} />
              <Route path='/sendmessages' element={<SendMessagesList />} />
              <Route path='/faildmessages' element={<FaildMessagesList />} />
              <Route path='/adduser' element={<AddUser />} />
              <Route path='/allcustomers' element={<AllCustomer /> } />
               <Route path='/singlecustomer' element={<SingleCustomer />} />
               <Route path="*" element={<AdminDashboard />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
  
  
      );
  }
}

export default App;