import React, { useState } from 'react';
import Navbar from "../componenets/Navbar";
import Sidebar from "../componenets/Sidebar";
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
//import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(./assets/img/logo-HS)',
    backgroundSize: 'cover',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

async function SignUpUser(credentials) {
  return fetch('http://bpodistributions.click:3010/users/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
      
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
  
 }

 function AddUser() {
  const classes = useStyles();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [confirmpassword, setConfirmPassword] = useState();
  const [email, setEmail] = useState();
  const accessToken= 'njbhjkhgfjgfgjhgdghjbkgjfjhgf';
  const navigate = useNavigate();


  const handleSubmit = async e => {
    e.preventDefault();
    const response = await SignUpUser({
      username,
      password,
      confirmpassword,
      email, 
      accessToken
    });
    console.log(response);
    if ('access_token' in response) {
      swal("Success", "User Added", "success", {
        buttons: false,
        timer: 2000,
      })
      .then((value) => {
        window.location.href = "/";
      });
    } else {
      swal("Failed", response.message, "error");
    }
      
    
  }
    return(
        <>
            <Navbar />
            <div className="row">
             <Sidebar />
            </div> 
            <main className="main-content position-relative h-100 border-radius-md contained ">
                <div className="container-fluid g-3 ">
                  <h4 className="colorfix sizefix mx-2">Add User</h4>
                  <div className="card px-2 pt-3 pb-3 mb-4 cardbg">
                    <div className="container-fluid" style={{backgroundColor:"white"}}>
                      <div className="container-fluid px-0 ">
                          <div className="col-12">
                                          <div className="col-6" >
                                            <form className={classes.form} noValidate onSubmit={handleSubmit}>
                                              <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="username"
                                                name="email"
                                                label="User Name"
                                                onChange={e => setUserName(e.target.value)}
                                              />
                                              <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="email"
                                                name="email"
                                                label="Email"
                                                type="email"
                                                onChange={e => setEmail(e.target.value)}
                                              />
                                              <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="password"
                                                name="password"
                                                label="Password"
                                                type="password"
                                                onChange={e => setPassword(e.target.value)}
                                              />
                                              <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="confirmpassword"
                                                name="password"
                                                label="Confirm Password"
                                                type="password"
                                                onChange={e => setConfirmPassword(e.target.value)}
                                              />
                                              <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className={classes.submit}
                                              >
                                                Add User
                                              </Button>
                                            </form>
                                          </div> 
                          </div>
                      </div>
                    </div>
                  </div>
                </div>   
            </main>
        </>
    )
}

export default AddUser ;