import Navbar from "../componenets/Navbar";
import Sidebar from "../componenets/Sidebar";
import React, { useState, useEffect}  from 'react';
import TotalSentMessages from "../admin/components/TotalMessages";
import AllcustomersTable from "../admin/components/AllcustomersTable";
import { useNavigate, Link  } from "react-router-dom";
import { read, utils, writeFile } from 'xlsx';
import { format } from 'date-fns';
import swal from "sweetalert";
import axios from "axios";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from "@mui/material";


import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

//test data for table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#596CFF",
        fontWeight: "bold",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
 
//end test table data
//delete user



//end delete user
 function SendMessagesList() {
    const [customers, setCustomers] = useState([]);
    const [areaLocation, setAreaLocation] = useState('');
    const [area, setArea] = useState([]);
    const navigate = useNavigate();

    const penjaja = [
      'KOMPLEKS TUN SAMBANTHAN (PINES) BAZAR',
      'MEDAN SELERA PENJAJA TAMAN SETIAWANGSA',
      'MEDAN SELERA SEKSYEN 4 WANGSA MAJU',
      'PP TAMAN BOTANI, JALAN CENDERAWASIH',
      'PP TEMPAT LETAK KERETA JLN BERHALA',
      'PUSAT PENJAJA LOT PT 432 (D\'TASEK)',
      'PUSAT PENJAJA PARK & RIDE SUNGAI BESI',
      'ANJUNG SELERA, JALAN JUJUR',
      'MEDAN SELERA TAMAN SUNGAI BESI',
      'PASAR BERBANGUNAN KERAMAT MALL',
      'KIOSK LAMAN SEJAHTERA PPR SG BONUS',
      'PUSAT PENJAJA D REJANG',
      'PASAR BERBANGUNAN UTC SENTUL',
      'PASAR BESAR TAMAN TUN DR ISMAIL',
      'PASAR DAN PP JLN WIRAWATI KG PANDAN',
      'PASAR DAN PP WANGSA MAJU SEKYEN 2',
      'PUSAT PENJAJA TPCA JALAN RAJA MUDA',
      'PASAR DAN PUSAT PENJAJA TMN SERI SENTOSA',
      'PASAR HARIAN SELAYANG KUALA LUMPUR',
      'PASAR SUNGAI BESI',
      'PP LITTLE INDIA II (KEDAI BUNGA)',
      'PUSAT PENJAJA AIR PANAS',
      'PP SEMENTARA TAMAN KEJIRANAN AIR PANAS',
      'PUSAT PENJAJA BANDAR SERI PERMAISURI',
      'PUSAT PENJAJA BAZARIA PANTAI',
      'PUSAT PENJAJA DANAU KOTA',
      'MEDAN SELERA SEK 4 WANGSA MAJU',
      'ANJUNG SELERA JALAN JUJUR',
      'PASAR SG BESI',
      'PP PARK & RIDE SG BESI',
      'PASAR WANGSA MAJU SEK. 2',
      'PUSAT KOMUNITI BANDAR TUN RAZAK',
      'PUSAT PENJAJA D\'LAMAN RASA PEKAN SG BESI',
      'PUSAT PENJAJA D\'REJANG',
      'PUSAT PENJAJA JALAN DUTA',
      'PUSAT PENJAJA JALAN JEJAKA MALURI',
      'PUSAT PENJAJA JALAN LOBAK',
      'PUSAT PENJAJA JALAN MEGA MENDUNG',
      'PUSAT PENJAJA JALAN USAHAWAN',
      'PASAR DAN PP KG BARU SALAK SELATAN',
      'MEDAN SELERA D TASIK',
      'PASAR DAN PUSAT PENJAJA JALAN KLANG LAMA',
      'PUSAT PENJAJA METRO PRIMA',
      'PASAR DAN PUSAT PENJAJA RAZAK MANSION',
      'PUSAT PENJAJA PT80',
      'PUSAT PENJAJA PUTRA RIA',
      'PUSAT PENJAJA SELERA MEDAN BUNUS',
      'PUSAT PENJAJA SRI ANGKASA JAYA',
      'PP TAMAN BOTANI JALAN CENDERAWASIH',
      'PUSAT PENJAJA TPCA, JALAN RAJA MUDA',
      'PUSAT SEMBELIHAN AYAM SEMENTARA',
      'MEDAN SELERA DAMAI JALAN BATU BATA',
      'PP LORONG ANTARA 495 ATAU 497 JLN TAR',
      'MEDAN SELERA D TEBING RIVER OF LIFE'
     
    ];
    const perumahan = [
      'PA SRI PULAU PINANG',
      'PA SRI SABAH 3A',
      'PA SRI SABAH 3B',
      'PA SRI JOHOR 4A',
      'PA SRI JOHOR 4B',
      'PA SRI JOHOR 4C',
      'PA SRI MELAKA (4D)',
      'PA SERI MELAKA (4F)',
      'PA SERI MELAKA (4G)',
      'PA SRI LABUAN',
      'PA SRI KOTA',
      'PPR DESA TUN RAZAK',
      'PPR TAMAN MULIA',
      'PPR DESA PETALING',
      'PPR RAYA PERMAI',
      'PPR PUDU ULU',
      'PPR JALAN COCHCRANE (LAKSAMANA)',
      'PPR COCHRANE PERKASA',
      'PPR SRI MALAYSIA',
      'PA (S) IKAN EMAS 1',
      'PA (S) IKAN EMAS 2',
      'PA JLN LOKE YEW',
      'PA LOKE YEW 2',
      'PA LOKE YEW 3',
      'PA LOKE YEW 4',
      'PA JLN. HANG TUAH',
      'PA SRI SELANGOR 1',
      'PA SRI SELANGOR 2',
      'KOMPLEKS SAN PENG',
      'PA SRI SARAWAK',
      'PA KG. KERINCHI 1A',
      'PPR SERI PANTAI',
      'PPR SRI ANGGERIK',
      'PPR PANTAI RIA',
      'PPR SRI CEMPAKA.',
      'PPR SRI ALAM',
      'PPR KG.MUHIBBAH',
      'PPR SALAK SELATAN',
      'PPR KG LIMAU',
      'PPR KERINCI LEMBAH PANTAI',
      'PPR BUKIT JALIL',
      'PPR BUKIT JALIL BLOK L&M',
      'LOT BANGLO KAYU KERINCHI',
      'KUARTERS PANTAI PERMAI',
      'KUARTER KEPONG',
      'PA SRI TRENGGANU 1',
      'PA SRI KELANTAN C',
      'PA SRI PANGKOR 1',
      'PA SRI NEGRI SEMBILAN',
      'PA SRI PERAK',
      'PPR MTEN BERINGIN',
      'PPR TAMAN WAHYU',
      'PPR MTEN PEKAN BATU',
      'PPR MTEN KG. BATU MUDA',
      'PPR MTEN INTAN BAIDURI',
      'SENTUL UTARA (KS)',
      'PA SRI KEDAH',
      'PA SRI TIOMAN I',
      'PA SETAPAK JAYA 1A',
      'PA SRI PERLIS 2',
      'PA SRI PERLIS 1',
      'PA DESA REJANG',
      'PPR JELATEK',
      'PPR KG.BARU AIR PANAS',
      'PPR SG BONUS',
      'PPR WANGSA SARI',
      'PPR GOMBAK SETIA',
      'PPR HILIRAN AMPANG',
      'PA GOMBAK MODEN 3B',

    ]
    
        async function findCustomer(req) {
            console.log(req.target.value)
            if(req.target.value){
                document.getElementById('search-table').style.display = "block"
            }
            
            if(!req.target.value){
                document.getElementById('search-table').style.display = "none"
            }
            
            const account_no = req.target.value
            return fetch('http://bpodistributions.click:3010/messages/get_bills_single_customer', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({account_no})
            })
            .then(response => response.json())
            .then(responseJson => {
              console.log(responseJson);
              setCustomers(responseJson)
            })
            
           }
        
        //find customers by area
        async function findCustomerLocation(e) {
          console.log(e)
          if(e.target.value){
              document.getElementById('search-table-area').style.display = "block"
          }
          
          if(!e.target.value){
              document.getElementById('search-table-area').style.display = "none"
          }
          
          const location = e.target.value
          console.log(location);
          setAreaLocation(location);
          return fetch('http://bpodistributions.click:3010/messages/allsent_messages', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({location})
          })
          .then(response => response.json())
          .then(responseJson => {
            console.log(responseJson);
            setArea(responseJson)
          })
          
         }

        //select area
        async function findScript(req){
          console.log(req.target.value)
          if(req.target.value === 'penjaja'){
            document.getElementById('penjaja').style.display = "block"
            document.getElementById('perumahan').style.display = "none"
          }
          else{
            document.getElementById('perumahan').style.display = "block"
            document.getElementById('penjaja').style.display = "none"
          }
        }

      //create excel sheet
      const GetAllCustomersByArea = async() =>{
        var wb = utils.book_new();
        var ws = utils.json_to_sheet(area);
        
        utils.book_append_sheet(wb, ws, "MySheet1");

        writeFile(wb, `${areaLocation}List.xlsx`);
      }
    return(
        <>
            <Navbar />
            <div className="row">
                <Sidebar />
                
            </div> 
            <main className="main-content position-relative h-100 border-radius-md contained ">
                <div className="container-fluid g-3 ">
                <h4 className="colorfix sizefix mx-2">All Successfully Sent Messages</h4>
                    <div className="card px-2 pt-3 pb-3 mb-4 cardbg">
                        <div className="container-fluid" style={{backgroundColor:"white"}}>
                            <div className="container-fluid px-0 ">
                                <div className="col-12">
                                    <div className="container-fluid pb-4 pt-3 g-3">
                                        <div className="row ">

                                            <Box className="row" component="form" sx={{'& .MuiTextField-root': { m: 1, width: '40ch' }, }} noValidate autoComplete="off" >
                                                <div className="col-5">
                                                    <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="Search bills by Account Number"
                                                    defaultValue=""
                                                    onChange={findCustomer}
                                                    />
                                                    
                                                </div>
                                                <div className="col-5 mt-2">
                                                  <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Choose Script</InputLabel>
                                                        <Select
                                                          labelId="demo-simple-select-label"
                                                          id="demo-simple-select"
                                                          label="Select Area"
                                                          onChange={findScript}
                                                        >
                                                          <MenuItem value={'penjaja'}>Penjaja</MenuItem> 
                                                          <MenuItem value={'perumahan'}>Perumahan</MenuItem>
                                                        </Select>
                                                  </FormControl>
                                                </div>
                                               
                                                <div className="col-8 mt-2 mx-2"  id="perumahan" style={{display: "none"}}>
                                                <FormControl fullWidth>
                                                      <InputLabel id="demo-simple-select-label">Choose Location (Perumahan)</InputLabel>
                                                      <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Select Area"
                                                        onChange={findCustomerLocation}
                                                      >
                                                              {perumahan.map(item=>(
                                                                <MenuItem value={item}>{item}</MenuItem>
                                                              )

                                                              )}
                                                      </Select>
                                                </FormControl>

                                                </div>

                                                <div className="col-8 mt-2 mx-2"  id="penjaja" style={{display: "none"}}>
                                                  <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Choose Location (Penjaja)</InputLabel>
                                                        <Select
                                                          labelId="demo-simple-select-label"
                                                          id="demo-simple-select"
                                                          label="Penjaja"
                                                          onChange={findCustomerLocation}
                                                        >
                                                              {penjaja.map(item=>(
                                                                <MenuItem value={item} >{item}</MenuItem>
                                                              )

                                                              )}
                                                                
                                                                
                                                        </Select>
                                                  </FormControl>
                                                </div>
                                                
                                                
                                            </Box>
                                            
                                                
                                            <div id="search-table" style={{display: "none"}}>
                                                <legend><b>All Bills Sent to Customer</b></legend>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                      <TableRow>
                                                      
                                                      </TableRow>
                                                        <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>ID</StyledTableCell>
                                                            <StyledTableCell>NAMA</StyledTableCell>
                                                            <StyledTableCell align="center">NO. AKAUN AIR</StyledTableCell>
                                                            <StyledTableCell align="center">SENDER NAME</StyledTableCell>
                                                            <StyledTableCell align="center">NO. TELEFON</StyledTableCell>
                                                            <StyledTableCell align="center">CURRENT AMOUNT</StyledTableCell>
                                                            <StyledTableCell align="center">TOTAL DUE AMOUNT</StyledTableCell>
                                                            <StyledTableCell align="center">DUE DATE</StyledTableCell>
                                                            <StyledTableCell align="center">KAWASAN SEWAAN</StyledTableCell>
                                                            <StyledTableCell align="center">SENT DATE</StyledTableCell>
                                                            
                                                           
                                                        </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                        {customers?.map(item => (
                                                            <StyledTableRow key={item.customer_id} id={item.no_akaun_air}>
                                                            <StyledTableCell>{item.id}</StyledTableCell>
                                                            <StyledTableCell>
                                                                {item.nama}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">{item.no_akaun_air}</StyledTableCell>
                                                            <StyledTableCell align="center">{item.sender_name}</StyledTableCell>
                                                            <StyledTableCell align="center">{item.no_telefon_1}</StyledTableCell>
                                                            <StyledTableCell align="center">{item.current_amount}</StyledTableCell>
                                                            <StyledTableCell align="center">{item.due_amount}</StyledTableCell>
                                                            <StyledTableCell align="center">{item.due_date}</StyledTableCell>
                                                            <StyledTableCell align="center">{item.kawasan_sewaan}</StyledTableCell>
                                                            <StyledTableCell align="center">{item.createdAt}</StyledTableCell>

                                                            </StyledTableRow>
                                                        ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                            
                                            <div id='search-table-area' className="mt-4" style={{display: "block"}}>
                                            
                                            <legend><b>Total Bills by Area</b></legend>
                                            <legend ><Button variant="contained" size="larg" onClick={GetAllCustomersByArea}> download</Button></legend>
                                            <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                      <TableRow>
                                                      
                                                      </TableRow>
                                                        <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>ID</StyledTableCell>
                                                            <StyledTableCell>NAMA</StyledTableCell>
                                                            <StyledTableCell align="center">NO. AKAUN AIR</StyledTableCell>
                                                            <StyledTableCell align="center">SENDER NAME</StyledTableCell>
                                                            <StyledTableCell align="center">NO. TELEFON</StyledTableCell>
                                                            <StyledTableCell align="center">CURRENT AMOUNT</StyledTableCell>
                                                            <StyledTableCell align="center">TOTAL DUE AMOUNT</StyledTableCell>
                                                            <StyledTableCell align="center">DUE DATE</StyledTableCell>
                                                            <StyledTableCell align="center">KAWASAN SEWAAN</StyledTableCell>
                                                            <StyledTableCell align="center">SENT DATE</StyledTableCell>
                                                            
                                                           
                                                        </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                        {area?.map(item => (
                                                            <StyledTableRow key={item.customer_id} id={item.no_akaun_air}>
                                                            <StyledTableCell>{item.id}</StyledTableCell>
                                                            <StyledTableCell>
                                                                {item.nama}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">{item.no_akaun_air}</StyledTableCell>
                                                            <StyledTableCell align="center">{item.sender_name}</StyledTableCell>
                                                            <StyledTableCell align="center">{item.no_telefon_1}</StyledTableCell>
                                                            <StyledTableCell align="center">{item.current_amount}</StyledTableCell>
                                                            <StyledTableCell align="center">{item.due_amount}</StyledTableCell>
                                                            <StyledTableCell align="center">{item.due_date}</StyledTableCell>
                                                            <StyledTableCell align="center">{item.kawasan_sewaan}</StyledTableCell>
                                                            <StyledTableCell align="center">{item.createdAt}</StyledTableCell>

                                                            </StyledTableRow>
                                                        ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            
                                                
                                            </div>
                                            
                                            <br className="pt-4"/>
                                            <hr />
                                            {/* <AllcustomersTable /> */}
                                            
                                            {/* <TotalSentMessages /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default SendMessagesList ;