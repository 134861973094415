import { NavLink } from "react-router-dom";
import React from 'react';

  
const Navbar = () => {
 
    const username = localStorage.getItem('username');

    const handleLogout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("roles");
        
        window.location.href = "/";
    };
    return (
        <>
        
            <nav className="navbar navbar-main navbar-expand-lg px-0 mx-0 shadow- border-radius-md fixed-start sticky-top border-bottom bordertester" id="navbarBlur"
                navbar-scroll="true" style={{backgroundColor: "white", position: "fixed", width: "100%"}}>
                <div className="container-fluid" >
                    <nav aria-label="breadcrumb col-8">

                        <NavLink className="navbar-brand font-weight-bolder mb-0 fs-4 align-middle " to="/">
                            <img src={localStorage.getItem('logo')} height="50" className="d-inline-block" alt="" />
                        </NavLink>
                        <h4 className="d-inline-flex align-middle"> &nbsp;| &nbsp;</h4>
                        <span className="d-inline-flex align-middle colorfix ">Welcome, &nbsp;</span> 
                        <span className="text-bolder text-capitalize align-middle colorfix" >{username}</span>
                    </nav>
                    <div className="col-4" style={{position:"relative",left: "25%"}}>
                        <NavLink to="/"><img src='https://cdn-icons-png.flaticon.com/512/149/149071.png' alt=""  style={{width: "30px", marginRight: "2%"}} /></NavLink>
                        <NavLink className="d-inline-flex" onClick={handleLogout} style={{marginRight: "2%"}}>Logout</NavLink>
                    </div>
                    
            </div>
            
            </nav> 
        </>
     );
}
 
export default Navbar;