import React from "react";

function Footer() {
  return (
    <>
        <footer className="w-100 py-4 flex-shrink-0">
            <div className="container">
                <div className="row gy-4 gx-5">
                    <div className="col-lg-4 col-md-6">
                        <p className="small text-muted mb-0">&copy; Copyrights. All rights reserved to <a className="text-primary" href="#">BPO Distributions</a></p>
                    </div>
                    
                </div>
            </div>
        </footer>
    </>
  );
}

export default Footer;