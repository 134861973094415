//Pending Table
import React from "react";
import { useState, useEffect } from "react";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import swal from 'sweetalert';
//table components
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

//test data for table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#596CFF",
        fontWeight: "bold",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  

//end test table data



const SentMessageTable = (props) => {

    //set messages
    const [messages, setMessages] = useState();
    const [dataErrors, setDataErrors] = useState({});
    const navigate = useNavigate();

    async function sendMessage(item) {
      await axios
         .post("http://bpodistributions.click:3010/messages/send_bill", item)
         .then((response) => {
           console.log(response.data)
           if('success' in response.data){
             swal("Success", "Sent", "success", {
               buttons: false,
               timer: 2000,
             })
             setMessages([])
               navigate("/messages");
           }else{
             swal("Failed", "Not Sent", "error", {
               buttons: false,
               timer: 2000,
             })
             setMessages([])
             navigate("/messages");
           }
             
           
         });
     }
    useEffect(()=> {

        //test preview
        const fetchData = async () => {
            // get the data from the api
            const dataResponse = await fetch(`http://bpodistributions.click:3010/customers/get_bills`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                }
              })
            // convert data to json
            const json = await dataResponse.json();
            console.log(json)
            setMessages(json);

        }
        fetchData()
        

        
    },[dataErrors]); 
    
    
    

    return (
        <>  
           
            <div className='pb-2 pt-2 tableoverflow tablesize-lg'>
            <legend><b>Total Customer List</b></legend>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell>NAMA</StyledTableCell>
                            <StyledTableCell align="center">NO. AKAUN AIR</StyledTableCell>
                            <StyledTableCell align="center">NO. TELEFON (1)</StyledTableCell>
                            <StyledTableCell align="center">NO. TELEFON (2)</StyledTableCell>
                            <StyledTableCell align="center">ALAMAT UNIT</StyledTableCell>
                            <StyledTableCell>CURRENT DUE AMOUNT</StyledTableCell>
                            <StyledTableCell>ARREAS AMOUNT</StyledTableCell>
                            <StyledTableCell>TOTAL DUE AMOUNT</StyledTableCell>
                            <StyledTableCell>DUE DATE</StyledTableCell>
                            <StyledTableCell>ACTIONS</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {messages?.map(item => (
                            <StyledTableRow key={item.customer_id}>
                            <StyledTableCell>{item.id}</StyledTableCell>
                            <StyledTableCell>
                                {item.customer.nama}
                            </StyledTableCell>
                            <StyledTableCell align="center">{item.customer.no_akaun_air}</StyledTableCell>
                            <StyledTableCell align="center">{item.customer.no_telefon_1}</StyledTableCell>
                            <StyledTableCell align="center">{item.customer.no_telefon_2}</StyledTableCell>
                            <StyledTableCell align="center">{item.customer.kawasan_sewaan}</StyledTableCell>
                            <StyledTableCell align="center">{item.current_amount}</StyledTableCell>
                            <StyledTableCell align="center">{item.arrears_amount}</StyledTableCell>
                            <StyledTableCell align="center">{item.total_due_amount}</StyledTableCell>
                            <StyledTableCell align="center">{item.due_date}</StyledTableCell>
                            <StyledTableCell align="center">

                                <button className="btn btn-primary" onClick={() => 
                                    sendMessage(item)}>
                                      Send</button></StyledTableCell>
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            
                
            </div>
            
            
        </>
        
    )
}

export default SentMessageTable;
