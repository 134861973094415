import Navbar from "../componenets/Navbar";
import Sidebar from "../componenets/Sidebar";
import React, { useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';

import swal from 'sweetalert';

const accessToken = localStorage.getItem('accessToken')
const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: 'url(./assets/img/logo-HS)',
      backgroundSize: 'cover',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

 

async function sendMessage() {

  swal("Sending Messages ....", "Sending Messages ....", "info", {
    buttons: false,
    timer: 6000,
  })

    return fetch('http://bpodistributions.click:3010/messages/send_broadcast', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(data => {
        let response = data.json()
        
          swal("Success", "Sent Messages ....", "success", {
            buttons: false,
            timer: 2000,
          })
      
      })
      
    
   }

 const SendMessage = () =>{
    const sendername = localStorage.getItem('username')

    
    
    return(
        <>
            <Navbar />
            <div className="row">
             <Sidebar />
            </div> 
            <main className="main-content position-relative h-100 border-radius-md contained ">
                <div className="container-fluid g-3 ">
                <h4 className="colorfix sizefix mx-2">Send Broadcast Message</h4>
                    <div className="card px-2 pt-3 pb-3 mb-4 cardbg">
                        <div className="container-fluid" style={{backgroundColor:"white"}}>
                            <div className="container-fluid px-0 ">
                                <div className="col-12">
                                    <div className="container-fluid pb-4 pt-3 g-3">
                                        <div className="row g-0">
                                          <h3>Welcome Message</h3>
                                          <button className="btn btn-primary col-3" onClick={() => sendMessage()}>Send To All</button>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                
            </main>
        </>
    )
}

export default SendMessage ;