//Pending Table
import React from "react";
import { useState, useEffect } from "react";
import dateFormat from "dateformat";

//table components
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

//test data for table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#596CFF",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  

//end test table data

const SentMessageTable = (props) => {


    const [messages, setMessages] = useState();
    const [dataErrors, setDataErrors] = useState({});

    useEffect(()=> {

        //test preview
        const fetchData = async () => {
            // get the data from the api
            const dataResponse = await fetch(`http://bpodistributions.click:3010/customers/get_customers`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                }
              })
            // convert data to json
            const json = await dataResponse.json();
            console.log(json)
            setMessages(json);

        }
        fetchData()
        

        
    },[dataErrors]); 
    
    return (
        <>
            <div className='pb-2 pt-2 tableoverflow tablesize-sm'>
            <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell>NAMA</StyledTableCell>
                            <StyledTableCell align="middle">NO. AKAUN AIR</StyledTableCell>
                            <StyledTableCell align="middle">NO. TELEFON (1)</StyledTableCell>
                            <StyledTableCell align="middle">NO. TELEFON (2)</StyledTableCell>
                            <StyledTableCell align="middle">KAWASAN SEWAAN</StyledTableCell>
                            
                            
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {messages?.map(item => (
                            <StyledTableRow key={item.customer_id}>
                            <StyledTableCell>{item.id}</StyledTableCell>
                            <StyledTableCell>
                                {item.nama}
                            </StyledTableCell>
                            <StyledTableCell align="middle">{item.no_akaun_air}</StyledTableCell>
                            <StyledTableCell align="middle">{item.no_telefon_1}</StyledTableCell>
                            <StyledTableCell align="middle">{item.no_telefon_2}</StyledTableCell>
                            <StyledTableCell align="middle">{item.kawasan_sewaan}</StyledTableCell>
                            
                           
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
               
            </div>
            
            
        </>
        
    )
}

export default SentMessageTable;


//Repayment Balance